import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

interface MessageProps {
    message: {
        id: string;
        content: string;
        role: 'user' | 'assistant';
    };
    isNew: boolean;
    isSending: boolean;
}

const LoadingDots: React.FC = () => {
    const [dotCount, setDotCount] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setDotCount((prevCount) => (prevCount % 3) + 1);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box display="flex" alignItems="center" minHeight="56px" minWidth="40px">
            {Array.from({ length: dotCount }).map((_, index) => (
                <Box key={index} component="span" className="dot" mx={0.5} sx={{ width: 5, height: 5, borderRadius: '50%', backgroundColor: 'white' }} />
            ))}
        </Box>
    );
};

const Message = React.forwardRef<HTMLDivElement, MessageProps>(({ message, isNew, isSending }, ref) => {
    const [content, setContent] = useState(message.content);
    const messageContent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setContent(message.content);
    }, [message.content]);

    const renderContent = () => {
        if (content.startsWith('https://storage.googleapis.com/greenchart-dev.appspot.com/traderai')) {
            return <img src={content} alt="Content" style={{ maxWidth: 300, maxHeight: 115, height: 'auto' }} />;
        }
        return <ReactMarkdown>{content}</ReactMarkdown>;
    };

    const isImage = content.startsWith('https://storage.googleapis.com/greenchart-dev.appspot.com/traderai');

    return (
        <motion.div
            initial={isNew ? { opacity: 0, translateY: 20 } : {}}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.5 }}
            ref={ref}
            style={{ width: '100%', display: 'flex', justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start' }}
        >
            <Box p={1} display="flex" justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'} width="100%" boxSizing="border-box">
                <Box
                    bgcolor={isImage ? 'transparent' : message.role === 'user' ? '#19a2fe' : '#2f2f2f'}
                    color="white"
                    px={2}
                    borderRadius={message.role === 'user' ? '16px 16px 0px 16px' : '0px 16px 16px 16px'}
                    maxWidth="60%"
                    display="flex"
                    alignItems="center"
                >
                    {isSending && message.role === 'assistant' ? (
                        <LoadingDots />
                    ) : (
                        <Typography style={{ textAlign: 'left' }} ref={messageContent}>
                            {renderContent()}
                        </Typography>
                    )}
                </Box>
            </Box>
        </motion.div>
    );
});

export default Message;
