import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useChat } from '../hooks/useChat';

const Header: React.FC = () => {
    const { restartChat } = useChat();

    const handleRestart = () => {
        restartChat();
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{ background: '#333' }}
        >
            <Typography variant="h6" color="white" sx={{ flex: 1, textAlign: 'center' }}>
                TraderAI
            </Typography>
            <IconButton color="inherit" onClick={handleRestart}>
                <RefreshIcon sx={{ color: 'white' }} />
            </IconButton>
        </Box>
    );
};

export default Header;
