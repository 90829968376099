import React, {useEffect, useState} from "react";
import {Box, Card, CardMedia, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImagePreview: React.FC<{ file: File | null; onRemove: () => void }> = ({ file, onRemove }) => {
    const [preview, setPreview] = useState<string | null>(null);

    useEffect(() => {
        if (!file) {
            setPreview(null);
            return;
        }
        const objectUrl = URL.createObjectURL(file);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [file]);

    if (!file) return null;

    return (
        <Box sx={{ position: 'absolute', bottom: 90, left: 0, zIndex: 1 }}>
            <Card
                sx={{
                    width: 56,
                    height: 56,
                    position: 'relative',
                    overflow: 'visible',
                    '&:hover .close-icon': {
                        display: 'block',
                    },
                }}
            >
                <CardMedia
                    component="img"
                    sx={{ width: 56, height: 56 }}
                    image={preview || ''}
                    alt="Selected Image"
                />
                <IconButton
                    size="small"
                    className="close-icon"
                    sx={{
                        position: 'absolute',
                        top: -15,
                        right: -15,
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        display: 'none',
                        height: '30px',
                        width: '30px',
                        zIndex: 2,
                    }}
                    onClick={onRemove}
                >
                    <CloseIcon sx={{ fontSize: 16, color: 'white' }} />
                </IconButton>
            </Card>
        </Box>
    );
};

export default ImagePreview;