import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { firebase_functions } from '../config/firebaseConfig';

interface Message {
    id: string;
    content: string;
    role: 'user' | 'assistant';
    imageUrl?: string;
}

interface ChatContextProps {
    messages: Message[];
    sendMessage: (message: Omit<Message, 'id'>) => Promise<void>;
    restartChat: () => void;
    initChat: (messages: Message[]) => void;
    sendingMessageToAgent: boolean;
    loadingConversation: boolean;
    deletingConversation: boolean;
    fetchAnotherPage: () => Promise<void>;
    hasMore: boolean;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

interface ChatProviderProps {
    children: ReactNode;
}

const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [lastMessageId, setLastMessageId] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const [getConversation, loadingConversation] = useHttpsCallable(firebase_functions, 'getAgentConversation');

    useEffect(() => {
        const fetchMessages = async () => {
            const res: any = await getConversation({ pageSize: 10 });
            if (res?.data?.messages) {
                setMessages(res.data.messages);
                setHasMore(res.data.hasMore);

                if (res.data.messages.length > 0) {
                    setLastMessageId(res.data.messages[0].id);
                }
            }
        };

        fetchMessages();
    }, [getConversation]);

    const fetchAnotherPage = async () => {
        if (!hasMore || !lastMessageId) return;

        const res: any = await getConversation({ pageSize: 10, startAfter: lastMessageId });
        if (res?.data?.messages) {
            setMessages((prevMessages) => [...res.data.messages, ...prevMessages]);
            setHasMore(res.data.hasMore);

            if (res.data.messages.length > 0) {
                setLastMessageId(res.data.messages[0].id);
            }
        }
    };

    const [sendMessageToAgent, sendingMessageToAgent] = useHttpsCallable(
        firebase_functions,
        'sendMessageToAgent'
    );

    const [deleteConversation, deletingConversation] = useHttpsCallable(
        firebase_functions,
        'deleteConversation'
    );

    const sendMessage = async (message: Omit<Message, 'id'>) => {
        const userMessageId = String(messages.length);
        const userMessage: Message = { ...message, id: userMessageId, role: 'user' };

        // Add user message
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        if (message.imageUrl) {
            const imageMessageId = String(messages.length + 1);
            const imageMessage: Message = { id: imageMessageId, content: message.imageUrl || '', role: 'user', imageUrl: message.imageUrl };

            setMessages((prevMessages) => [...prevMessages, imageMessage]);
        }

        const tempMessageId = String(messages.length + (message.imageUrl ? 2 : 1));
        const tempMessage: Message = { id: tempMessageId, content: '. . .', role: 'assistant' };

        // Add a placeholder assistant message
        setMessages((prevMessages) => [...prevMessages, tempMessage]);

        try {
            const res: any = await sendMessageToAgent(message);

            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.id === tempMessageId
                        ? {
                            ...msg,
                            content: res?.data?.data ?? 'Something went wrong. Please contact support if this problem persists.'
                        }
                        : msg
                )
            );
        } catch (error) {
            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.id === tempMessageId ? { ...msg, content: 'Error sending message' } : msg
                )
            );
        }
    };

    const restartChat = () => {
        setMessages([]);
        deleteConversation().then(() => {
            setTimeout(() => {
                setMessages([{ id: '0', content: 'Hello! How can I help you today?', role: 'assistant' }]);
            });
        });
    };

    const initChat = (messages: Message[]) => {
        setMessages(messages);
    };

    return (
        <ChatContext.Provider value={{ messages, sendMessage, restartChat, initChat, sendingMessageToAgent, loadingConversation, deletingConversation, fetchAnotherPage, hasMore }}>
            {children}
        </ChatContext.Provider>
    );
};

export { ChatContext, ChatProvider };
