import React, { useState } from 'react';
import { Box, TextField, IconButton, InputAdornment, CircularProgress, useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useChat } from '../hooks/useChat';
import { styled } from '@mui/system';
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { firebase_functions } from "../config/firebaseConfig";
import ImagePreview from "./ImagePreview";

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: '#858585',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#494949',
        },
        '&:hover fieldset': {
            borderColor: '#575757',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#626262',
        },
    },
}));

const ChatInput: React.FC = () => {
    const [input, setInput] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const { sendingMessageToAgent, sendMessage, deletingConversation } = useChat();
    const isMobile = useMediaQuery('(max-width:600px)');

    const [uploadImage, uploadingImage] = useHttpsCallable(
        firebase_functions,
        'uploadImage'
    );

    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result && typeof reader.result === 'string') {
                    resolve(reader.result.split(',')[1]);
                } else {
                    reject(new Error('File conversion error'));
                }
            };
            reader.onerror = error => reject(error);
        });
    };

    const handleSend = async () => {
        setInput('');

        if (!input.trim() && !file) return;

        let fileUrl = '';

        if (file) {
            try {
                setFile(null);

                const base64File = await convertToBase64(file);
                const res = await uploadImage({ file: base64File, originalname: file.name });
                fileUrl = (res?.data as { url: string }).url;
            } catch (error) {
                return;
            }

            const userMessage = { content: input, role: 'user' as const, imageUrl: fileUrl };
            await sendMessage(userMessage);
        } else {
            const userMessage = { content: input, role: 'user' as const };
            await sendMessage(userMessage);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            {file && <ImagePreview file={file} onRemove={handleRemoveFile} />}
            <Box display="flex" px={isMobile ? 0 : 3}>
                <StyledTextField
                    sx={{ background: '#303030' }}
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    InputProps={{
                        style: { color: 'white' },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{ color: 'white' }}
                                    onClick={handleSend}
                                    disabled={sendingMessageToAgent}
                                >
                                    {sendingMessageToAgent || uploadingImage || deletingConversation ? (
                                        <CircularProgress size={24} sx={{ color: 'green' }} />
                                    ) : (
                                        <SendIcon />
                                    )}
                                </IconButton>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="icon-button-file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton component="span" sx={{ color: 'white' }}>
                                        <AttachFileIcon />
                                    </IconButton>
                                </label>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        style: { color: 'white' },
                    }}
                />
            </Box>
        </Box>
    );
};

export default ChatInput;
