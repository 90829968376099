import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ChatProvider } from '../contexts/ChatContext';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import Header from "./Header";

const ChatApp: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <ChatProvider>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                sx={{ background: '#212121' }}
            >
                <Box width="100%" maxWidth={isMobile ? '100vw' : '80vw'}>
                    <Header />
                    <ChatWindow />
                    <ChatInput />
                </Box>
            </Box>
        </ChatProvider>
    );
};

export default ChatApp;
