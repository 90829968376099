import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import Box from "@mui/material/Box";
import GreenChartSubscriptionRouteGuard from "./guards/GreenChartSubscriptionRouteGuard";
import ChatApp from './components/ChatApp';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/*' element={
                    <GreenChartSubscriptionRouteGuard>
                        <Box className="App" >
                            <ChatApp />
                        </Box>
                    </GreenChartSubscriptionRouteGuard>
                }/>
            </Routes>
        </Router>
    );
}

export default App;